<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M13 7.5a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0zm2 0a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0z"
    />
    <path d="M19.707 18.293a1 1 0 0 1-1.414 1.414l-6-6a1 1 0 0 1 1.414-1.414l6 6z" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
